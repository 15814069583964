import React from 'react';

import PasswordRequest from '../../components/auth/passwordRequest/passwordRequest';
import AuthLayout from '../../components/global/auth/authLayout/authLayout';
import { AuthPageBase } from '../../context/global-context';
import { AuthPageData, AuthPageType } from '../../types/auth';
import { LocalizationData } from '../../types/general';
import { PageContext } from '../../types/page';
import { LocaleEnum, getLocales, useI18n } from '../../utils/i18n';

type AuthPasswordResetContext = PageContext & {
  locale: LocaleEnum;
  slug: string;
};

const PasswordRequestPage: React.FC<{
  pageContext: AuthPasswordResetContext;
}> = ({ pageContext }) => {
  const { translations, locale, slug } = pageContext;

  const i18n = useI18n(translations);
  const locales = getLocales([locale]);
  const localizationsData: LocalizationData[] = [];

  locales.forEach((l) => {
    const slug = i18n.t('forgot.password.slug', l);

    localizationsData.push({
      attributes: {
        slug: slug ? slug : 'forgot-password',
        locale: l,
      },
    });
  });

  const instance: AuthPageData = {
    authPageType: AuthPageType.forgotPassword,
    attributes: {
      slug: slug,
      locale: locale,
      localizations: {
        data: localizationsData,
      },
    },
  };

  return (
    <AuthPageBase translations={translations} instance={instance}>
      <AuthLayout>
        <PasswordRequest />
      </AuthLayout>
    </AuthPageBase>
  );
};

export default PasswordRequestPage;
