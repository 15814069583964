import React from 'react';
import Header from '../../header/header';

import './authLayout.scss';

const AuthLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <>
      <Header />
      {children}
    </>
  );
};

export default AuthLayout;
