import axios from 'axios';
import React, { useContext, useState } from 'react';

import GlobalContext from '../../../context/global-context';
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';
import { ReactComponent as BackArrow } from '../../../images/back2.svg';
import { ReactComponent as WarningSVG } from '../../../images/warning_circle_red.svg';
import { LocaleType } from '../../../utils/i18n';
import { EMAIL_VALIDATION } from '../../../utils/regexes';
import './passwordRequest.scss';
import { pathToLogin } from '../../../utils/user';
import Loading from '../../global/loading/loading';

const PasswordRequest: React.FC = () => {
  const { i18n, invitationToken } = useContext(GlobalContext);
  const currentLocale: LocaleType = useLocaleFromRouter();

  const [email, setEmail] = useState({
    value: '',
    isValid: false,
  });

  const [error, setError] = useState(false);
  const [forgotPwdError, setForgotPwdError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const resetAllError = () => {
    setError(false);
    setForgotPwdError(false);
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const isValid = EMAIL_VALIDATION.test(e.currentTarget.value);
    setEmail({ value: e.currentTarget.value, isValid });
  };

  const checkValidityOnSubmit = (): boolean => {
    return email.isValid;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    // Already submitting
    if (submitting) {
      return;
    }

    resetAllError();

    if (!checkValidityOnSubmit()) return setError(true);
    if (checkValidityOnSubmit()) {
      try {
        setSubmitting(true);

        axios
          .post(`${process.env.GATSBY_STRAPI_URL}/api/auth/forgot-password`, {
            email: email.value,
            locale: currentLocale,
          })
          .then((response) => {
            if (response.status === 200) {
              resetAllError();
              setSuccess(true);
            }
          })
          .catch((err) => {
            resetAllError();
            setForgotPwdError(true);
          })
          .finally(() => {
            setSubmitting(false);
          });
      } catch (err) {
        resetAllError();
        setForgotPwdError(true);
        setSubmitting(false);
      }
    }
  };

  return (
    <div className="auth-content-wrapper">
      <div className="auth-form-wrapper password-request-wrapper">
        <div className="back-to-login">
          <a href={pathToLogin(currentLocale, i18n, invitationToken)}>
            <div className="arrow-container">
              <BackArrow />
            </div>
            <span>{i18n?.t('return_to_login')}</span>
          </a>
        </div>
        {success && (
          <>
            <h2 className="auth-title">{i18n?.t('check_your_email')}</h2>
            <p>
              {i18n
                ?.t('reset.password.email_sent')
                .replace('[email]', email.value)}
            </p>
            <p>{i18n?.t('check_your_email_spam')}</p>
          </>
        )}
        {!success && (
          <>
            <h2 className="auth-title">{i18n?.t('reset.password.title')}</h2>
            <span className="auth-subtitle">
              {i18n?.t('reset.password.email_instructions')}
            </span>
            <form className="password-request-form" onSubmit={handleSubmit}>
              <div>
                <label>{i18n?.t('form.labels.email2')}</label>
                <input type="text" onChange={handleChange}></input>
              </div>
              <button className="submit-button" type="submit">
                {submitting && <Loading type="btn" />}
                {!submitting && i18n?.t('validate')}
              </button>
            </form>
            {error && (
              <div className="error-container">
                <div className="icon-container">
                  <WarningSVG />
                </div>
                <p>{i18n?.t('form.errors.all_fields')}</p>
              </div>
            )}
            {forgotPwdError && (
              <div className="error-container">
                <div className="icon-container">
                  <WarningSVG />
                </div>
                <p>{i18n?.t('form.errors.reset_password_request')}</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PasswordRequest;
